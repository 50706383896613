<template>
  <div class="role-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="角色权限管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 角色名称 -->
        <ml-input
          prop="roleNameLike"
          placeholder="请输入角色名称"
          style="margin-bottom: 0; margin-right: 40px"
          label="角色名称："
          v-model="searchData.roleNameLike"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 添加账号 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          :showSubmit="false"
          cancelText="添加角色"
          cancelIcon="el-icon-plus"
          cancelType="primary"
          @click-cancel="clickPush"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-if="isBusinessDepartment"
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="10%"
        /> -->
        <el-table-column align="center" label="角色名称" prop="roleName" min-width="15%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="角色权限"
          prop="moduleName"
          min-width="25%"
        />
        <el-table-column align="center" label="角色描述" prop="remark" min-width="25%" />
        <el-table-column align="center" label="创建时间" prop="createTime" min-width="12%" />
        <el-table-column align="center" label="操作" priop="roleId" min-width="20%">
          <template #default="scope">
            <el-button
              v-if="scope.row.roleCode !== 'SUPERADMIN'"
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              v-if="scope.row.roleCode !== 'SUPERADMIN'"
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteRoleDialogRef"
      :content="deleteRoleData.content"
      @click-submit="submitDeleteRole"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'RoleManage',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      roleNameLike: getMemoryPage.value.searchData.roleNameLike || ''
    })

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getRoleTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getRoleTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getRoleTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.roleNameLike = replacePerCent(searchData.roleNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetSAysRoles',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getRoleTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 添加
    const clickPush = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/roleManageAction' })
    }
    // 修改
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/roleManageAction', query: { roleId: row.roleId } })
    }
    // 删除
    const deleteRoleDialogRef = ref()
    const deleteRoleData = reactive({
      content: '确定删除该角色？'
    })
    const clickDelete = (index, row) => {
      deleteRoleData.roleId = row.roleId
      deleteRoleDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteRole = () => {
      const { roleId } = deleteRoleData
      dispatch('fetchDeleteSAysRole', roleId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getRoleTabberData() : (tabberData.page = 1)
          deleteRoleDialogRef.value.showDialog = false
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      getRoleTabberData()
    })
    return {
      isBusinessDepartment,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickPush,
      clickUpdate,
      clickDelete,
      deleteRoleData,
      deleteRoleDialogRef,
      submitDeleteRole
    }
  }
}
</script>

<style lang="scss" scoped>
.role-manage {
  @extend %params-global;
}
</style>
